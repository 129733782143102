// ==================================================
//  Mainvisual スライダー
// ==================================================
$(document).ready(function () {
  if ($(".mainvisualSlider-js").length) {
    $(".mainvisualSlider-js").not(".slick-initialized").slick({
      infinite: true,
      autoplay: true,
      autoplaySpeed: 3600,
      speed: 1200,
      fade: true,
      dots: false,
      swipe: false,
      arrows: false,
      pauseOnFocus: false,
      pauseOnHover: false,
    });
  }
});

// ==================================================
//  Staff スライダー
// ==================================================
function checkBreakPoint() {
  if (is_mobile()) {
    $(".staffSlider-js").not(".slick-initialized").slick({
      infinite: true,
      autoplay: true,
      autoplaySpeed: 3600,
      speed: 600,
      dots: false,
      swipe: false,
      arrows: false,
      pauseOnFocus: false,
      pauseOnHover: false,
      adaptiveHeight: true,
      centerMode: true,
      centerPadding: "50px",
    });
  } else {
    $(".staffSlider-js.slick-initialized").slick("unslick");
  }
}
$(window).resize(function () {
  checkBreakPoint();
});
checkBreakPoint();
